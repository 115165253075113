<template>
  <div class="info">
    <top-bar :title="'活动详情'" :left="true"></top-bar>
    <div class="config" v-if="!changeOrDetail">
      <div class="top">
        <div class="title">{{dataForm.title}}</div>
        <div class="content-wrap">
          <div class="content">{{dataForm.content}}</div>
          <van-uploader v-model="newFileList" readonly :show-upload="false" :deletable="false"/>

          <div class="content-footer">
            <div class="footer-left">
              <van-icon name="underway-o" style="font-size: 16px" />
              {{dataForm.createTime}}
            </div>
            <!-- <div class="footer-right">30人已读</div> -->
          </div>
          <div class="detail-info">
            <div class="detail-title">活动须知</div>
            <div class="detail-item">
              <div class="item-icon">
                <van-image :src="require('@/assets/img/activeFlag.png')"></van-image>
              </div>
              <div class="apply" v-if="dataForm.needSign == 1">
                报名人数：
                <span class="content" style="margin-right: 4px">{{dataForm.signNum}}人</span>
                剩余：<span class="content">{{dataForm.joinNum - dataForm.signNum >= 0 ? dataForm.joinNum
                - dataForm.signNum : 0}}人</span>
              </div>
              <div class="apply" v-else>
                活动人数：
                <span class="content">{{dataForm.joinNum}}人</span>
              </div>
              <div style="flex: 1; text-align: right">
                <div class="check-btn" @click="checkHandle">查看</div>
              </div>
            </div>
            <div class="detail-item">
              <div class="item-icon">
                <van-image :src="require('@/assets/img/activeLocation.png')"></van-image>
              </div>
              <div class="apply">活动地点：<span class="content">{{dataForm.address}}</span></div>
            </div>
            <div class="detail-item">
              <div class="item-icon">
                <van-image :src="require('@/assets/img/activeTime.png')"></van-image>
              </div>
              <div class="apply">
                <div style="width: 78px;">活动时间：</div>
                <div class="content">{{dataForm.beginTime}} 至 {{dataForm.endTime}}</div>
              </div>
            </div>
            <div class="detail-item" v-if="dataForm.needSign == 1">
              <div class="item-icon">
                <van-image :src="require('@/assets/img/activeTime.png')"></van-image>
              </div>
              <div class="apply">报名截止时间：<span class="content">{{dataForm.signEndTime}}</span></div>
            </div>
          </div>
        </div>
      </div>
      <template v-if="dataForm.needSign == 0 && isEdit == 1">
        <van-row class="btns" gutter="10" style="background: transparent">
          <van-col :span="12">
            <van-button plain type="danger" size="large" round @click="delt" style="background-color:transparent">删除
            </van-button>
          </van-col>
          <van-col :span="12">
            <van-button type="info" size="large" round @click="changeOrDetail = !changeOrDetail">编辑</van-button>
          </van-col>
        </van-row>
      </template>
      <template v-if="dataForm.needSign == 1 && dataForm.putaway == 1">
        <van-row class="btns" style="background: transparent">
          <van-col :span="24">
            <van-button v-if="!dataForm.sign && dataForm.canSign && applyShow" type="info" size="large" color="#387FF5"
              style="border-radius: 10px" @click="signSave">报名</van-button>
            <van-button v-if="dataForm.sign && unApplyShow" type="info" size="large" color="#387FF5"
              style="border-radius: 10px" plain @click="cancelSign">取消报名</van-button>
          </van-col>
        </van-row>
      </template>
    </div>
    <template v-if="changeOrDetail">
      <van-popup v-model="typeShow" position="bottom" style="max-height: 50%;">
        <div class="typeCheck">
          <span @click="typeShow = false">取消</span>
          <span>请选择</span>
          <span @click="typeConfirm">确定</span>
        </div>
        <van-checkbox-group class="typeCheckBox" @change="change" v-model="checkedValue">
          <van-cell-group v-for="(item, index) in typeList" :key="index">
            <van-cell :title="item.label" @click="toggle(index)">
              <template #icon>
                <van-checkbox :name="item" ref="checkboxes" />
                <van-icon v-if="item.children !== null" :name="childrenVisible ?'arrow-up':'arrow-down'"
                  @click.stop="childrenVisible = !childrenVisible" />
              </template>
            </van-cell>
            <van-checkbox-group v-if="childrenVisible" class="children" @change="childrenChange"
              v-model="childrenValue">
              <van-cell-group v-for="(items, i) in item.children" :key="i">
                <van-cell :title="items.label" @click="toggleChildren(i)">
                  <template #icon>
                    <van-checkbox :name="items" ref="childrenBoxes" />
                  </template>
                </van-cell>
              </van-cell-group>
            </van-checkbox-group>
          </van-cell-group>
        </van-checkbox-group>
        <!--      <van-checkbox-group ref="checkboxGroup" @change="change" v-model="checkedValue">-->
        <!--        <van-cell-group>-->
        <!--          <van-cell v-for="(item, index) in typeList" :key="index" :title="item.label" @click="toggle(index)">-->
        <!--            <template #right-icon>-->
        <!--              <van-checkbox :name ="item" ref="checkboxes"/>-->
        <!--            </template>-->
        <!--          </van-cell>-->
        <!--        </van-cell-group>-->
        <!--      </van-checkbox-group>-->
        <!--      <van-picker title="标题" value-key="label" show-toolbar :columns="typeList" @confirm="typeConfirm" @cancel="typeShow = !typeShow" />-->
      </van-popup>
      <van-popup v-model="beginDateShow" position="bottom">
        <van-datetime-picker @confirm="beginDateConfim" @cancel="beginDateShow = false" type="datetime" title="选择年月日"
          :min-date="new Date(2017, 0, 1)" :max-date="new Date()" />
      </van-popup>
      <van-popup v-model="endDateShow" position="bottom">
        <van-datetime-picker @confirm="endDateConfim" @cancel="endDateShow = false" type="datetime" title="选择年月日"
          :min-date="new Date(2017, 0, 1)" :max-date="new Date(2025, 10, 1)" />
      </van-popup>
      <van-popup v-model="surveyObjectShow" position="bottom">
        <van-picker title="面向对象" value-key="label" show-toolbar :columns="surveyObjectOptions"
          @confirm="surveyObjectConfirm" @cancel="surveyObjectShow = !surveyObjectShow" />
      </van-popup>
      <van-popup v-model="applyStartShow" position="bottom">
        <van-datetime-picker type="datetime" title="请选择报名开始日期" confirm-button-text="确定" @confirm="applyStartConfirm"
          @cancel="applyStartShow= false" />
      </van-popup>
      <van-popup v-model="applyEndShow" position="bottom">
        <van-datetime-picker type="datetime" title="请选择报名结束日期" confirm-button-text="确定"
          :min-date="new Date(dataForm.signBeginTime)" @confirm="applyEndConfirm" @cancel="applyEndShow= false" />
      </van-popup>
      <van-popup v-model="needSignShow" position="bottom">
        <van-picker title="是否需要报名" value-key="label" show-toolbar :columns="options" @confirm="needSignConfirm"
          @cancel="needSignShow = !needSignShow" />
      </van-popup>

      <!-- 列表 -->
      <van-cell>
        <template #title>
          <div class="blue-box"></div>
          <span class="custom-title">基本信息</span>
        </template>
      </van-cell>
      <van-field clearable clear-trigger="always" v-model="dataForm.title" label="活动名称" :readonly="!changeOrDetail" required
        placeholder="请输入" input-align="right" />
      <van-field clearable clear-trigger="always" v-model="dataForm.address" label="活动地点" :readonly="!changeOrDetail" required
        placeholder="请输入" input-align="right" />
      <van-cell title="开始时间" :value="dataForm.beginTime || '请选择'" @click="beginDateShow = changeOrDetail" required
        :value-class="{'value-common':!dataForm.beginTime}" :is-link="changeOrDetail" />
      <van-cell title="结束时间" :value="dataForm.endTime || '请选择'" @click="endDateShow = changeOrDetail" required
        :value-class="{'value-common':!dataForm.endTime}" :is-link="changeOrDetail" />
      <van-field clearable clear-trigger="always" v-model="dataForm.joinNum" label="参与人数" :readonly="!changeOrDetail" required
        placeholder="请输入" input-align="right" />
      <van-field clearable clear-trigger="always" v-model="dataForm.speaker" label="主讲人" :readonly="!changeOrDetail"
        placeholder="请输入" input-align="right" />
      <van-field clearable clear-trigger="always" v-model="dataForm.mobile" label="联系电话" :readonly="!changeOrDetail"
        placeholder="请输入" input-align="right" />
      <van-cell title="活动类型" :value="dataForm.typeStr || '请选择'" @click="typeShow = changeOrDetail" required
        :value-class="{'value-common':!dataForm.type}" :is-link="changeOrDetail" />
      <van-cell title="面向对象" is-link :value="dataForm.surveyObjectStr || '居民'" required
        @click="surveyObjectShow = changeOrDetail" />
      <van-cell title="是否需要报名" is-link :value="needSign" @click="needSignShow = changeOrDetail" required />
      <template v-if="dataForm.needSign">
        <van-cell title="报名时间" is-link
          :value="dataForm.signBeginTime ? dataForm.signBeginTime + '至' + dataForm.signEndTime : ''"
          @click="applyStartShow = changeOrDetail" />
      </template>
      <van-field clearable clear-trigger="always" v-model="dataForm.content" rows="3" class="left" :border="false"
        :readonly="!changeOrDetail" type="textarea" input-align="right" maxlength="50" placeholder="请输入活动详情" />
      <van-row class="uploadBox" style="background-color: #fff;">
        <van-col :span="24">
          <!-- <van-uploader v-model="solveFileList" :deletable="false" :show-upload="false"/> -->
          <div class="upload">
            <upload-file :file-list="newFileList" :del-btn-show="changeOrDetail" :uploadPath="uploadPath"
              @filesUpload="filesUpload" @delFile="delFile" :upload-btn-show="changeOrDetail" :file-type="'image'" />
          </div>
        </van-col>
      </van-row>
      <van-row v-if="changeOrDetail" class="btns" gutter="10">
        <van-col :span="12">
          <van-button plain type="danger" size="large" round @click="changeOrDetail = !changeOrDetail"
            style="background-color:transparent">删除
          </van-button>
        </van-col>
        <van-col :span="12">
          <van-button type="info" size="large" round @click="submit">完成</van-button>
        </van-col>
      </van-row>
    </template>

  </div>
</template>

<script>
import topBar from '@/components/topBar/topBar'
import { formatterDateTimeWithOutSec } from '@/utils/utils'
import uploadFile from '@/components/upload/uploadFile'
import { mapState } from 'vuex'
import { getImageStream } from '@/utils/index'
import { getVirtualDict } from "@/utils/common";

export default {
  components: {
    uploadFile,
    topBar
  },
  computed: { ...mapState(['activityId']) },
  data() {
    return {
      isEdit: 0,
      firstValue: [],
      secondValue: [],
      uploadPath: 'files/activity/temporary',
      changeOrDetail: false,
      beginDateShow: false,
      endDateShow: false,
      typeShow: false,
      isTasks: false,
      childrenVisible: false,
      surveyObjectShow: false,
      applyStartShow: false,
      applyEndShow: false,
      needSignShow: false,
      typeStr: '',
      surveyObjectStr: '居民',
      typeList: [],
      fileList: [],
      surveyObjectOptions: [{ value: '1', label: '居民' }, { value: '0', label: '社工/村委' }],
      options: [{ value: 1, label: '是' }, { value: 0, label: '否' }],
      newFileList: [],
      deleteFiles: [],
      checkedValue: [],
      childrenValue: [],
      needSign: '',
      dataForm: {
        id: '',//活动id
        title: '',  //活动名称
        address: '', //活动地址
        beginTime: '', //开始时间
        endTime: '', //结束时间
        joinNum: '', //参与人数
        typeOptions: [],//活动类型选择器下拉列表
        type: '', //活动类型
        content: '', //活动描述
        speaker: '',  //主讲人
        mobile: '', //联系电话
        fileList: [],
        surveyObject: '1',  //默认面向居民
        needSign: '',
        signBeginTime: '',
        signEndTime: '',
        putaway: 0
      },
      unApplyShow: false,
      applyShow: false
    }
  },
  methods: {
    checkHandle() {
      this.$router.push({ path: '/actives-apply-info', query: { id: this.dataForm.id } })
    },
    filesUpload(files) {
      this.fileList = files
    },
    delFile(id) {
      this.deleteFiles.push(id)
    },
    getInfo() {
      this.$toast.loading({ duration: 0, message: '加载中...', forbidClick: true, });
      this.$http({
        url: this.$http.adornUrl('/wxapp/activity/info'),
        method: 'post',
        params: this.$http.adornParams({
          id: this.isTasks === true ? this.dataForm.id : this.activityId,
          orgId: this.$globalData.userInfo.orgId,
          orgUserId: this.$globalData.userInfo.userId
        })
      }).then(({ data }) => {
        console.log(data);
        if (data.code == 0) {
          if (data.entityVO.fileMessages && data.entityVO.fileMessages.length != 0) {
            data.entityVO.fileMessages.forEach(item => {
              let u;
              u = getImageStream(item.relativePath)
              let url = {
                uid: this.dataForm.fileList.length,
                id: item.id,//多图需要记录一个uid，随图片数量增加而增加
                status: 'done',//uploading上传中，done上传完成，error上传失败
                fileType: item.fileType,
                relativePath: item.relativePath,
                url: u,
                realUrl: item.relativePath.replace(/\\/g, "/")
              }
              this.dataForm.fileList.push(url);
              this.newFileList.push(url);
            })
          }
          this.dataForm = data.entityVO
          this.needSign = data.entityVO.needSign == 1 ? '是' : '否'
          let type = this.dataForm.type.split(',').map((n) => Number(n))
          this.typeList.map(item => {
            type.map(val => {
              if (item.value == val) {
                this.checkedValue.push(item)
              }
              if (item.children !== null) {
                item.children.map(e => {
                  if (e.value == val) {
                    this.childrenValue.push(e)
                  }
                })
              }
            })
          })
          if (new Date() < new Date(this.dataForm.endTime.replace(/-/g, '/'))) {
            this.unApplyShow = true
          } else {
            this.unApplyShow = false
          }

          if (this.dataForm.needSign && new Date() > new Date(this.dataForm.signBeginTime.replace(/-/g, '/'))
            && new Date() < new Date(this.dataForm.signEndTime.replace(/-/g, '/')) && this.dataForm.joinNum - this.dataForm.signNum > 0) {
            this.applyShow = true
          } else {
            this.applyShow = false
          }
          this.dataForm.id = this.activityId
        }
        this.$toast.clear()
      }, err => { this.$toast.clear() })
    },
    getTypeOptions() {
      this.$http({
        url: this.$http.adornUrl('/wxapp/sys/dict/listDictByCode'),
        method: 'post',
        params: this.$http.adornParams({
          code: 'activityType',
          orgId: this.$orgId,
          showDisabled: false
        })
      }).then(({ data }) => {
        if (data.code == 0) {
          let dicts = data.dicts
          let index = dicts.findIndex(item => {
            if (item.label === '党建引领') {
              return true
            }
          })
          if (index >= 0) {
            dicts.splice(index, 1)
          }
          this.typeList = dicts
        }
      }, err => { this.$toast.error({ message: '活动类型获取失败' }) })
    },
    /**
     * 获取活动面向对象
     */
    getActivitySurveyObjectOptions() {
      getVirtualDict('activitySurveyObject', (dicts) => {
        this.surveyObjectOptions = dicts
      })
    },
    signSave() {
      this.$toast.loading({
        duration: 0,
        message: '报名中...',
        forbidClick: true,
      });
      this.$http({
        url: this.$http.adornUrl('/wxapp/activity/sign/signUp'),
        method: 'post',
        params: this.$http.adornParams({
          activityId: parseInt(this.dataForm.id),
          orgUserId: this.$globalData.userInfo.userId
        })
      }).then(({ data }) => {
        this.$toast.clear()
        if (data && data.code === 0) {
          this.$toast.success({
            message: '报名成功',
            duration: 3000,
            onOpened: () => {
              setTimeout(() => {
                // this.isEdit = true;
                localStorage.setItem('isRefresh', 1)
                this.$toast.clear()
                this.getInfo()
              }, 1500)
            }
          })
        }
      })
    },
    cancelSign() {
      this.$dialog.confirm({
        message: '请确认是否取消报名',
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl('/wxapp/activity/sign/delete'),
          method: 'post',
          params: this.$http.adornParams({
            activityId: parseInt(this.dataForm.id),
            userId: this.$globalData.userInfo.userId
          })
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.$toast.success({
              message: '取消成功',
              duration: 3000,
              onOpened: () => {
                setTimeout(() => {
                  // this.isEdit = true;
                  localStorage.setItem('isRefresh', 1)
                  this.$toast.clear()
                  this.getInfo()
                }, 1500)
              }
            })
          } else {
            this.$toast.fail(data.msg)
          }
        })
      }).catch(() => {
        // console.log('不想取消了');
      });
    },
    submit() {
      let parentCheck = []
      let childrenCheck = []
      // debugger
      this.checkedValue.map(item => {
        if (!item.children || item.children.length < 1) {
          parentCheck.push(item.value)
        }
      })
      this.childrenValue.map(item => {
        childrenCheck.push(item.value)
      })
      this.dataForm.type = parentCheck.concat(childrenCheck)
      this.dataForm.type = this.dataForm.type.join(',')
      this.$http({
        url: this.$http.adornUrl('/wxapp/activity/update'),
        method: 'post',
        data: this.$http.adornData({
          ...this.dataForm,
          'newFileMessages': this.fileList.filter(item => !item.id),
          'deleteFiles': this.deleteFiles
        })
      }).then(({ data }) => {
        if (data.code == 0) {
          this.$toast.success('修改成功')
          this.changeOrDetail = false
        } else { this.$toast.fail({ message: data.msg }) }
      }, err => { this.$toast.fail({ message: '修改失败' }) })
    },
    // 删除数据
    delt() {
      this.$dialog.confirm({
        title: '删除',
        message: '确认删除吗',
      })
        .then(() => {
          this.$http({
            url: this.$http.adornUrl('/wxapp/activity/delete'),
            method: 'post',
            params: this.$http.adornParams({
              ids: this.activityId
            })
          }).then(({ data }) => {
            if (data.code == 0) {
              this.$toast.success({ message: '删除成功' })
              localStorage.setItem('isRefresh', 1)
              this.$router.go(-1)
            }
          }, err => { this.$toast.fail({ message: '删除失败' }) })
        })
        .catch(() => {
        });
    },
    // 删除图片
    uploaddel(file, detail) {
      this.$dialog.confirm({
        title: '删除',
        message: '确认删除图片吗',
      }).then(() => {
        let i = detail.index
        this.dataForm.fileList.splice(i, 1)
        this.deleteFiles.push(file.id)
      })
    },
    // 上传图片
    uploadRead(file) {
      this.$toast.loading({
        message: '上传中...',
        forbidClick: true,
        duration: 0,
        overlay: true
      });
      if (!Array.isArray(file)) {
        file = [file]
      }
      file.forEach((item, index2) => {
        let formdata = new FormData();
        formdata.append("file", item.file);
        formdata.append("path", 'files/activity/temporary');
        this.$http({
          url: this.$http.adornUrl('/wxapp/file/upload'),
          method: 'post',
          data: formdata
        }).then(({ data }) => {
          if (index2 === file.length - 1) {
            this.$toast.clear()
          }
          if (data.code == 0) {
            let uid = this.dataForm.fileList.length != 0 ? this.dataForm.fileList[this.dataForm.fileList.length - 1].uid + 1 : 0
            data.fileMessage['uid'] = uid
            this.dataForm.fileList.push(data.fileMessage)
            this.fileList.push(data.fileMessage)
            return true
          } else {
            this.$toast.fail(data.msg);
          }
        })
      })
    },
    typeConfirm() {
      this.typeShow = false
    },
    // change(val) {
    //   if (val.length > 1) {
    //     this.dataForm.typeStr = val.map(function(item,index){
    //       return item.label}).join(',')
    //     this.dataForm.type = []
    //     val.map(e => {
    //       this.dataForm.type.push(e.value)
    //     })
    //   } else {
    //     this.dataForm.typeStr = val[0].label
    //     this.dataForm.type = val[0].value
    //   }
    //   this.typeShow = true
    // },
    change(val) {
      console.log(this.checkedValue)
      this.firstValue = val
      this.dataForm.typeStr = val.map(function (item, index) {
        return item.label
      }).concat(this.secondValue.map(item => {
        return item.label
      })).join(',')
      // val.map(e => {
      //   this.dataForm.type.push(e.value)
      // })
      this.typeShow = true
    },
    childrenChange(val) {
      this.secondValue = val
      this.dataForm.typeStr = val.map(function (item, index) {
        return item.label
      }).concat(this.firstValue.map(item => {
        return item.label
      })).join(',')
    },
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
    },
    toggleChildren(index) {
      this.$refs.childrenBoxes[index].toggle();
    },
    surveyObjectConfirm(value) {
      this.surveyObjectStr = value.label
      this.dataForm.surveyObject = value.value
      this.dataForm.surveyObjectStr = value.label
      this.surveyObjectShow = false
    },
    beginDateConfim(value) {
      let d = new Date(this.dataForm.endTime)
      if (this.dataForm.endTime != '' && d.getTime(d) < value.getTime(value)) {
        return this.$toast.fail('开始时间不能比结束时间大哦')
      }
      this.dataForm.beginTime = formatterDateTimeWithOutSec(value)
      this.beginDateShow = false
    },
    endDateConfim(value) {
      let d = new Date(this.dataForm.beginTime)
      if (this.dataForm.beginTime != '' && d.getTime(d) > value.getTime(value)) {
        return this.$toast.fail('结束时间不能比开始时间小哦')
      }
      this.dataForm.endTime = formatterDateTimeWithOutSec(value)
      this.endDateShow = false
    },
    applyStartConfirm(value) {
      this.dataForm.signBeginTime = formatterDateTimeWithOutSec(value)
      this.applyStartShow = false
      this.applyEndShow = true
    },
    applyEndConfirm(value) {
      this.dataForm.signEndTime = formatterDateTimeWithOutSec(value)
      this.applyEndShow = false
    },
    needSignConfirm(value) {
      this.needSign = value.label
      this.dataForm.needSign = value.value
      this.needSignShow = false
    }
  },
  created() {
    if (this.$route.query.tasks) {
      this.dataForm.id = this.$route.query.id
      this.isTasks = true
    }
    this.isEdit = this.$route.query.isEdit
    this.getTypeOptions()
    this.getActivitySurveyObjectOptions()
    this.getInfo()
  }
}
</script>
<style lang="scss" scoped>
.info {
  padding: 0;
  height: 100vh;
  background: #fff;
}
.typeCheck {
  display: flex;
  justify-content: space-between;
  height: 150px;
  line-height: 115px;
  padding: 0 40px;
  span {
    display: block;
    font-size: 30px;
  }
  span:nth-child(1) {
    font-size: 28px;
    color: #969799;
  }
  span:nth-child(3) {
    font-size: 28px;
    color: #576b95;
  }
}

.typeCheckBox .van-cell__title {
  margin-left: 20px;
}

.typeCheckBox .van-icon {
  position: absolute;
  right: 40px;
  bottom: 45%;
}

.children {
  margin-left: 50px;
}

.children .van-cell__value {
  margin-left: 10px;
}

.config {
  border-radius: 10px;
  box-sizing: border-box;
  background: #FFFFFF;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .top {
    .title {
      text-align: center;
      font-size: 32px;
      font-family: PingFang-Bold;
      font-weight: 500;
      color: #333333;
      padding: 24px 0;
    }

    .content-wrap {
      .content-title {
        height: 88px;
        font-size: 32px;
        font-family: PingFang-Bold;
        font-weight: 600;
        color: #333333;
        line-height: 88px;
      }

      .content {
        font-size: 28px;
        font-family: PingFangSC-Regular;
        font-weight: 400;
        color: #333333;
      }

      .content-footer {
        height: 88px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .footer-left {
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
        }

        .footer-right {
          font-size: 24px;
          font-family: PingFang-Bold;
          font-weight: 500;
          color: #333333;
        }
      }
    }
  }

  .footer {
    margin-bottom: 40px;
  }

  .detail-info {
    // width: 690px;
    // height: 464px;
    background: #FFFFFF;
    box-shadow: 0px 2px 12px 0px rgba(229, 229, 229, 0.5);
    border-radius: 20px;
    padding: 0 20px;

    .detail-title {
      height: 88px;
      font-size: 32px;
      font-family: 'PingFang-Bold';
      font-weight: 600;
      color: #333333;
      line-height: 88px;
    }

    .detail-item {
      height: 88px;
      display: flex;
      align-items: center;
      border-bottom: 2px solid #EDEDED;
      font-size: 24px;
      font-family: 'PingFangSC-Regular';
      font-weight: 400;
      color: #666666;

      &:nth-last-child(1) {
        border: none;
      }

      .apply {
        display: flex;
        align-items: center;


      }

      .check-btn {
        display: inline-block;
        width: 108px;
        height: 56px;
        background: #387FF5;
        border-radius: 8px;
        font-size: 24px;
        font-family: 'PingFangSC-Regular';
        font-weight: 400;
        color: #FFFFFF;
        line-height: 56px;
        text-align: center;
      }

      .item-icon {
        width: 32px;
        height: 32px;
        margin-right: 10px;
      }

      .content {
        // margin-left: 10px;
        color: #373737;
      }
    }
  }
}
</style>
